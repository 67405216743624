<template>
  <v-dialog v-model="isOpen" scrollable width="500">
    <!-- <v-form @submit.prevent=""> -->
    <v-card>
      <v-card-title class="primary white--text">
        <span>Individueller Eintrag</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <v-row class="py-0 mb-0">
          <v-col class="py-0">
            <date-picker v-model="startsAt" label="Datum" :isRequired="true"></date-picker>
          </v-col>
          <v-col class="py-0">
            <date-picker v-model="endsAt" label="Enddatum" :isRequired="true" :disabled="type === 'custom'"></date-picker>
          </v-col>
        </v-row>

        <v-row class="py-0 mb-0">
          <v-col class="py-0">
            <v-text-field v-model="change" type="number" label="Änderung *" requried></v-text-field>
          </v-col>
          <v-col class="py-0">
            <v-select :items="types" label="Typ *" requried item-text="label" item-value="value" v-model="type"></v-select>
          </v-col>
        </v-row>

        <v-row class="py-0 mb-0">
          <v-col class="py-0">
            <v-text-field v-model="comment" label="Kommentar *" requried></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="close()">Abbrechen</v-btn>
        <v-btn color="primary" type="submit" :disabled="!valid" @click="save"> Speichern </v-btn>
      </v-card-actions>
    </v-card>
    <!-- </v-form> -->
  </v-dialog>
</template>

<script>
import moment from "moment";
import DatePicker from "../forms/input/DatePicker.vue";

export default {
  components: {
    DatePicker,
  },

  props: {
    employee: {
      requried: true,
    },
  },

  data: () => ({
    isOpen: false,
    startsAt: null,
    endsAt: null,
    change: null,
    comment: null,
    type: "custom",
    types: [
      { value: "custom", label: "Individuell" },
      { value: "vacation", label: "Ferien" },
      { value: "absence", label: "Absenz" },
    ],
  }),

  methods: {
    open(request, employee, overview) {
      this.isOpen = true;
    },

    close() {
      this.isOpen = false;
    },

    reset() {
      this.startsAt = null;
      this.endsAt = null;
      this.change = null;
      this.comment = null;
    },

    save() {
      this.$employeeVacationService
        .store(this.employee.id, {
          starts_at: this.startsAt.format("YYYY-MM-DDTHH:mm:ss"),
          ends_at: this.endsAt === null ? this.startsAt.format("YYYY-MM-DDTHH:mm:ss") : this.endsAt.format("YYYY-MM-DDTHH:mm:ss"),
          type: this.type,
          change: this.change,
          comment: this.comment,
        })
        .then(() => {
          this.$toast.open("Eintrag erfolgreicht gespeichert.");
          this.$emit("done");
          this.close();
        })
        .catch(() => {
          this.$toast.open({
            message: "Eintrag konnte nicht gespeichert werden.",
            type: "error",
          });
          this.close();
        });
    },

    calculateChange() {
      if (this.type !== "custom" && this.endsAt !== null) {
        this.$holidayService.calculateChange(this.startsAt.format("YYYY-MM-DD"), this.endsAt.format("YYYY-MM-DD")).then(({ data }) => {
          this.change = data;
        });
      }
    },
  },

  computed: {
    valid(...args) {
      return this.startsAt !== null && this.change != null && this.comment;
    },
  },
  watch: {
    isOpen(value) {
      if (!value) {
        this.reset();
      }
    },

    startsAt(value) {
      this.calculateChange();
    },
    endsAt(value) {
      this.calculateChange();
    },
    type(value) {
      this.calculateChange();
    },
  },
};
</script>
