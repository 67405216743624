<template>
  <div class="tt-table">
    <v-row class="justify-end" v-if="filters.length && $vuetify.breakpoint.mdAndDown">
      <v-col cols="auto" class="py-0">
        <tooltip-button icon="clear" text="Zurücksetzen" color="default" :dark="false" @click="resetFilters()" classNames="elevation-0 mt-5 mb-1" position="top" size="x-small" />
      </v-col>
      <v-col cols="auto" class="py-0">
        <v-chip class="mt-5" label>{{ total }}</v-chip>
      </v-col>
    </v-row>
    <v-row v-if="searchEnabled">
      <v-col cols="12" md="6" lg="4" class="py-0">
        <v-text-field v-model="search" prepend-icon="search" label="Suche..." @keyup.enter="getDataFromApi()" :maxlength="100"></v-text-field>
      </v-col>
      <v-col v-for="(filter, index) in filters" :key="index" cols="12" md="auto" class="py-0">
        <v-checkbox v-if="filter.type === 'checkbox'" v-model="selectedFilterValues[index].value" :label="filter.label"></v-checkbox>
        <v-select
          v-if="filter.type === 'select' || filter.type === 'select_multi'"
          v-model="selectedFilterValues[index].value"
          :items="filter.values"
          item-text="text"
          item-value="value"
          :label="filter.label"
          :single-line="filter.type === 'select'"
          :multiple="filter.type === 'select_multi'"
        ></v-select>
        <date-picker v-if="filter.type === 'datepicker'" v-model="selectedFilterValues[index].value" :label="filter.label" />
        <time-picker v-if="filter.type === 'timepicker'" v-model="selectedFilterValues[index].value" :label="filter.label" />
      </v-col>
      <template v-if="filters.length && $vuetify.breakpoint.lgAndUp">
        <v-col cols="auto" class="py-0" align-self="center">
          <tooltip-button icon="clear" text="Zurücksetzen" color="default" :dark="false" @click="resetFilters()" classNames="elevation-0 mb-2" position="top" size="x-small" />
        </v-col>
        <v-col cols="auto" class="py-0" align-self="center">
          <v-chip class="mb-2" label>{{ total }}</v-chip>
        </v-col>
      </template>
    </v-row>
    <v-data-table
      must-sort
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :server-items-length="total"
      :show-expand="showExpand"
      :single-expand="showExpand"
      :items-per-page="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 30],
        showFirstLastPage: true,
      }"
      :hide-default-footer="hideDefaultFooter"
      :page="page"
      @dblclick:row="(e, row) => $emit('dblclick', row.item)"
      dense
      class="elevation-0"
    >
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
        <!-- <h3 v-bind:key="name">{{ name }}</h3>
        <h3 v-bind:key="name">{{ slotData }}</h3> -->
        <slot :name="name" v-bind="slotData" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DatePicker from "@/components/forms/input/DatePicker";
import TimePicker from "@/components/forms/input/TimePicker";
import TooltipButton from "@/components/forms/input/TooltipButton";

export default {
  components: {
    TimePicker,
    DatePicker,
    TooltipButton,
  },

  props: {
    headers: {
      type: Array,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    sortBy: {
      default: null,
      required: false,
    },
    searchEnabled: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Array,
      required: false,
      default: () => [],
    },
    watchers: {
      type: Array,
      default: () => [],
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    with: {
      type: Array,
      default: () => [],
    },
    hideDefaultFooter: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data: () => ({
    items: [],
    total: 0,
    loading: true,
    options: {},
    page: 1,
    itemsPerPage: 15,
    sort: "",
    search: "",
    selectedFilterValues: [],
    fetchedData: false,
  }),

  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },

    selectedFilterValues: {
      handler(values) {
        if (this.fetchedData) {
          this.getDataFromApi();
        }

        const vm = this;
        this.watchers.forEach((watcher) => {
          watcher(vm, values);
        });

        this.page = 1;
      },
      deep: true,
    },

    url() {
      this.getDataFromApi();
    },
  },

  created() {
    if (this.sortBy) {
      this.sort = this.sortBy;
    }

    if (this.filters && this.filters.length > 0) {
      this.selectedFilterValues = this.filters.map((filter) => ({
        key: filter.field,
        value: filter.default,
        type: filter.type,
      }));
    }
  },
  methods: {
    /**
     * Fet data from API.
     */
    getDataFromApi() {
      this.loading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.itemsPerPage = itemsPerPage >= 0 ? itemsPerPage : this.total;
      this.page = page;

      // Prepare sort parameter.
      if (sortBy.length > 0) {
        this.sort = (sortDesc[0] ? "" : "-") + sortBy[0];
      }

      // Prepare filter parameters.
      let filterParams = null;
      if (this.selectedFilterValues.length > 0) {
        filterParams = this.selectedFilterValues.map((filter) => {
          let value = filter.value;
          if (filter.type === "datepicker" && filter.value) {
            value = filter.value.format("YYYY-MM-DD");
          } else if (filter.type === "timepicker" && filter.value) {
            value = filter.value.format("HH:mm") + ":00";
          }
          return filter.value ? `&${filter.key}=${value}` : "";
        });
      }

      // Fetch data from server.
      this.$api.http
        .get(
          `${this.url}${this.urlHasAlreadyQueries ? "&" : "?"}_page=${this.page}&_per_page=${this.itemsPerPage}&_sort=${this.sort}` +
            (this.with.length > 0 ? `&_with=${this.with.join(",")}` : "") +
            (this.searchEnabled ? `&_search=${this.search}` : "") +
            (filterParams ? filterParams.join("") : "")
        )
        .then((response) => {
          this.items = response.data.data;
          this.total = response.data.meta.total;

          this.loading = false;
          this.fetchedData = true;

          this.$emit("dataItems", response.data.data);
        });
    },

    /**
     * Reset all filters and fetch data.
     */
    resetFilters() {
      this.search = "";
      this.selectedFilterValues.forEach((filterValue, index) => {
        this.selectedFilterValues[index].value = this.filters[index].default;
      });
      this.getDataFromApi();
      this.page = 1;
    },
  },
  computed: {
    urlHasAlreadyQueries() {
      const url = new URL(`${this.$api.http.defaults.baseURL}/${this.url}`);
      return !!url.search;
    },
  },
};
</script>

<style lang="scss">
.tt-table {
  a {
    text-decoration: none;
    font-weight: bold;
    color: var(--v-accent-base) !important;
  }
}
</style>
