<template>
  <v-select :value="value" @input="select" :items="items" :item-text="itemText" :item-value="itemValue" :rules="selectionRule" :readonly="readonly" :disabled="items.length === 0 || readonly">
    <template v-slot:label>
      <span
        :class="{
          required: !readonly && required,
          disabled: items.length === 0,
        }"
        >{{ getLabel }}</span
      >
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    items: {
      required: true,
      type: Array,
    },
    label: {
      required: true,
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    itemValue: {
      type: String,
      default: "id",
      required: false,
    },
    itemText: {
      type: String,
      default: "name",
      required: false,
    },
  },

  computed: {
    getLabel() {
      return this.required ? `${this.label} *` : this.label;
    },
  },

  data() {
    return {
      selectionRule: this.required ? [(v) => !!v || "Muss selektiert werden"] : [],
    };
  },

  methods: {
    select(value) {
      this.$emit("input", value);
      this.$emit("selected", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.required {
  font-weight: 500;
  color: black;
}

.disabled {
  color: black;
  opacity: 0.6;
}
</style>
