<template>
  <div>
    <v-card color="default" outlined class="tt-card">
      <v-card-title class="header" style="position: relative">
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <v-icon left>keyboard_arrow_down</v-icon>
              {{ selectedYear }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="year in years" :key="year" @click="selectedYear = year">
              {{ year }}
            </v-list-item>
          </v-list>
        </v-menu>
        <div class="ml-4 grow">Abrechnungsperioden</div>
        <v-btn @click="$refs.customVacationDialog.open()">Individueller Eintrag</v-btn>
      </v-card-title>

      <v-card-text>
        <v-data-table :headers="headers" :items="filteredPeriods" :loading="loading" hide-default-footer dense class="elevation-0">
          <template v-slot:[`item.month`]="{ item }">
            {{ formatMonth(item.month) }}
          </template>
          <template v-slot:[`item.changes`]="{ item }">
            <div>{{ item.changes | addPrefix }} Tag(e)</div>
          </template>
          <template v-slot:[`item.balance`]="{ item }">
            <div>{{ item.balance }} Tag(e)</div>
          </template>
          <template v-slot:[`item.comments`]="{ item }">
            <span v-for="(comment, index) in item.comments" v-bind:key="index">
              <template v-if="comment.comment === '' || comment.comment === null">-</template>
              <template v-else>{{ comment.comment }}</template>
              ({{ comment.change | addPrefix }}; {{ absenceType(comment.type) }})
              <template v-if="index !== item.comments.length - 1">, </template>
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <custom-vacation-dialog ref="customVacationDialog" :employee="this.employee" @done="fetchData()"></custom-vacation-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import CustomVacationDialog from "../../dialogs/CustomVacationDialog.vue";

export default {
  components: {
    CustomVacationDialog,
  },

  props: {
    employee: {
      required: true,
    },
  },

  data: () => ({
    selectedYear: moment().format("YYYY"),
    years: [moment().format("YYYY")],
    headers: [
      { text: "Jahr", align: "left", value: "year" },
      { text: "Monat", align: "left", value: "month" },
      { text: "Änderung", align: "left", value: "changes" },
      { text: "Balance", align: "left", value: "balance" },
      { text: "Kommentar(e)", align: "left", value: "comments" },
    ],
    loading: true,
    periods: [],
  }),

  computed: {
    ...mapState("user", ["user"]),
    ...mapState("app", ["configs"]),

    filteredPeriods() {
      return this.periods.filter((period) => {
        return period.year == this.selectedYear;
      });
    },
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    formatMonth(month) {
      return moment()
        .month(month - 1)
        .format("MMMM");
    },
    formatDay(date) {
      return moment(date).format("D");
    },

    fetchData() {
      this.loading = true;
      this.$employeeVacationService
        .accountingPeriods(this.employee.id)
        .then(({ data }) => {
          this.periods = data.periods;
          this.years = data.years;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    absenceType(type) {
      const types = [
        { value: "custom", label: "Individuel" },
        { value: "vacation", label: "Ferien" },
        { value: "absence", label: "Absenz" },
      ];

      return types
        .find((t) => t.value === type)
        .label.charAt(0)
        .toUpperCase();
    },
  },

  watch: {
    employee() {
      this.fetchData();
    },
  },
};
</script>
