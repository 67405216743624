export default class DocumentService {
  url = "api/documents";

  constructor(api) {
    this.api = api;
  }

  getEmployeeLink() {
    return `${this.url}/documents`;
  }

  index(timeEntryId) {
    return this.api.http.get(`${this.url}/${timeEntryId}`);
  }

  store(timeEntryId, file) {
    return this.api.http.post(this.url, {
      timeEntryId,
      file,
    });
  }
}
