import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Api from "./services/api";
import "./filters/dateFilters";
import "./filters/unitFilters";
import "./filters/numberFilters";
import AbsenceRequestService from "./services/AbsenceRequestService";
import TimeEntryService from "./services/TimeEntryService";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import NotificationService from "./services/NotificationService";
import AdminTaskService from "./services/AdminTaskService";
import EmployeeService from "./services/EmployeeService";
import AccountingPeriodService from "./services/AccountingPeriodService";
import EmployeeVacationService from "./services/EmployeeVacationService";
import VacationService from "./services/VacationService";
import SupportService from "./services/SupportService";
import HolidayService from "./services/HolidayService";
import SettingService from "./services/SettingService";
import DocumentService from "./services/DocumentService";

Vue.config.productionTip = false;
Vue.use(VueToast, { position: "top" });

Vue.prototype.$toast = Vue.$toast;

const api = new Api(process.env.VUE_APP_API_URL, process.env.VUE_APP_COOKIE_DOMAIN);

// Define $api instance property
Vue.prototype.$api = api;
Vue.prototype.$adminTaskService = new AdminTaskService(api);
Vue.prototype.$notificationService = new NotificationService(api);
Vue.prototype.$timeEntryService = new TimeEntryService(api);
Vue.prototype.$absenceRequestService = new AbsenceRequestService(api);
Vue.prototype.$supportService = new SupportService(api);
Vue.prototype.$employeeService = new EmployeeService(api);
Vue.prototype.$accountingPeriodService = new AccountingPeriodService(api);
Vue.prototype.$employeeVacationService = new EmployeeVacationService(api);
Vue.prototype.$vacationService = new VacationService(api);
Vue.prototype.$holidayService = new HolidayService(api);
Vue.prototype.$settingService = new SettingService(api);
Vue.prototype.$documentService = new DocumentService(api);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
