import Vue from "vue";

Vue.filter("addPrefix", function (value) {
  if (value > 0) {
    return `+${value}`;
  }

  return value;
});

Vue.filter("removePrefix", function (value) {
  if (value < 0) {
    return value * -1;
  }
  return value;
});
