<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1 class="text-h4">Syncs</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="4">
        <sync-card
          syncKey="employees"
          :status="status.employees"
          @refresh="fetchSyncs"
          title="Mitarbeiter"
          subtitle="Comatic > Tingo Tools"
          info="Mitarbeiter werden vom Comatic ins Tingo Tools synchronisiert
                und können somit nur im Comatic bearbeitet werden."
        ></sync-card>
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <sync-card
          syncKey="projects"
          :status="status.projects"
          @refresh="fetchSyncs"
          title="Projekte"
          subtitle="Comatic > Tingo Tools"
          info=" Projekte und Budgets werden vom Comatic ins Tingo Tools
                synchronisiert und können somit nur im Comatic bearbeitet
                werden."
        ></sync-card>
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <sync-card
          syncKey="timetrackings"
          :status="status.timetrackings"
          @refresh="fetchSyncs"
          title="Zeiterfassung Projekte"
          subtitle="Comatic > Tingo Tools"
          info="Die erfassten Zeiten werden aus dem Comatic ins Tingo Tools
                übertragen und in den entprechenden Aufgaben und Budgets
                hinterlegt."
        ></sync-card>
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <sync-card
          disabled
          syncKey="timeEntries:current"
          :status="status.timeEntriesCurrent"
          @refresh="fetchSyncs"
          title="Zeiterfassung Mitarbeiter"
          subtitle="Comatic > Tingo Tools"
          info="Die erfassten Zeiten der Mitarbeiter werden aus dem Comatic ins Tingo Tools
                übertragen."
        ></sync-card>
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <sync-card
          syncKey="settings"
          :status="status.settings"
          @refresh="fetchSyncs"
          title="Einstellungen"
          subtitle="Comatic > Tingo Tools"
          info="Einstellungen wie Projekt-Status, Kategorien, etc. werden auch
                synchronisiert."
        ></sync-card>
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <sync-card
          syncKey="activities"
          :status="status.activities"
          @refresh="fetchSyncs"
          title="Arbeitsvorgang"
          subtitle="Comatic > Tingo Tools"
          info="Arbeitsvorgänge werden synchronisiert."
        ></sync-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SyncCard from "@/components/cards/SyncCard";

export default {
  components: {
    SyncCard,
  },

  data: () => ({
    status: {},
  }),

  mounted() {
    this.fetchSyncs();
  },

  methods: {
    async fetchSyncs() {
      const response = await this.$api.http.get("api/syncs");
      this.status = response.data;
    },
  },
};
</script>
