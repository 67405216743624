<template>
  <v-dialog v-model="isOpen" scrollable width="500">
    <v-card>
      <v-card-title :class="['primary', 'white--text']">
        <span class="subtitle-1">Aufgabe</span>
      </v-card-title>

      <v-card-text class="mt-3" v-if="request">
        <v-row class="mb-1">
          <v-col cols="3" class="py-0">Mitarbeiter</v-col>
          <v-col class="py-0">
            <strong>{{ employee.name }}</strong>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="3" class="py-0">Typ</v-col>
          <v-col class="py-0">
            <strong>
              {{ getAbsenceType(request.type) }}
            </strong>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="3" class="py-0">Info</v-col>
          <v-col class="py-0">
            <strong>
              {{ request.info }}
            </strong>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="3" class="py-0">Von</v-col>
          <v-col class="py-0">
            <strong>
              {{ formatDate(request.start) }}
            </strong>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="3" class="py-0">Bis und mit</v-col>
          <v-col class="py-0">
            <strong>
              {{ formatDate(request.end) }}
            </strong>
          </v-col>
        </v-row>

        <v-row class="mb-1">
          <v-col cols="3" class="py-0">Guthaben alt:</v-col>
          <v-col class="py-0">
            <strong>{{ daysLeft }}</strong>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="3" class="py-0">Guthaben neu:</v-col>
          <v-col class="py-0">
            <strong>{{ daysLeft + diffInDays }}</strong>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="3" class="py-0">Differenz:</v-col>
          <v-col class="py-0">
            <strong>{{ diffInDays }}</strong>
          </v-col>
        </v-row>

        <h3 class="mt-4">Wurde die Aufgabe in Comatic eingetragen?</h3>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="close()" class="ml-6 px-4"> Abbrechen </v-btn>
        <v-btn color="error" @click="completeAbsenceProcess()" class="ml-6 px-4"> Fertig Eingetragen </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  data: () => ({
    isOpen: false,
    id: null,
    employee: "",
    request: null,
    overview: null,
    daysLeft: null,
    diffInDays: 0,
  }),

  computed: {
    ...mapState("app", ["configs"]),

    startDate() {
      if (this.request) {
        return moment(this.request.start).format("DD.MM.YYYY");
      } else {
        return "";
      }
    },
    endDate() {
      if (this.request) {
        return moment(this.request.end).format("DD.MM.YYYY");
      } else {
        return "";
      }
    },
  },

  methods: {
    open(id, employee, overview) {
      this.isOpen = true;
      this.id = id;
      this.employee = employee;
      this.overview = overview;

      this.$absenceRequestService
        .getAbsence(id)
        .then((res) => {
          this.request = res.data;
        })
        .catch((err) =>
          this.$toast({
            message: "Absenz wurde nicht gefunden.",
            type: "error",
          })
        );

      this.fetchDaysLeft();
    },

    getAbsenceType() {
      return this.$absenceRequestService.getAbsenceType(this.configs.absence_types, this.request.type);
    },

    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },

    close() {
      this.reset();
      this.isOpen = false;
    },

    reset() {
      this.id = null;

      this.employee = "";
      this.request = null;
      this.overview = null;
      this.daysLeft = null;
      this.diffInDays = null;
    },

    completeAbsenceProcess() {
      const promiseTaskState = this.$absenceRequestService.changeOfficeTaskState(this.id);
      const promiseVacation = this.$employeeVacationService.store(this.employee.id, {
        starts_at: this.request.start,
        ends_at: this.request.end,
        comment: this.request.info,
        type: this.request.type === 2 ? "absence" : "vacation",
      });

      Promise.all([promiseTaskState, promiseVacation])
        .then(() => {
          this.$toast.open("Aufgabe erfolgreicht abgeschlossen.");
          this.$emit("done");
          this.close();
        })
        .catch(() => {
          this.$toast.open({
            message: "Eintrag konnte nicht abgeschlossen werden.",
            type: "error",
          });
          this.close();
        });
    },

    fetchDaysLeft() {
      this.$employeeVacationService.stats(this.employee.id).then(({ data }) => {
        this.daysLeft = data.netto;
      });
    },
  },
  watch: {
    async request(request) {
      if (request !== null) {
        this.diffInDays = await this.$holidayService.calculateChange(moment(this.request.start).format("YYYY-MM-DD"), moment(this.request.end).format("YYYY-MM-DD")).then(({ data }) => data);
      }
    },
  },
};
</script>
