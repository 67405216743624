var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"tt-card",attrs:{"color":"default","outlined":""}},[_c('v-card-title',{staticClass:"header",staticStyle:{"position":"relative"}},[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"white"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("event")]),_vm._v(" "+_vm._s(_vm.titleDate)+" ")],1)]}}]),model:{value:(_vm.openDatePicker),callback:function ($$v) {_vm.openDatePicker=$$v},expression:"openDatePicker"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1"},model:{value:(_vm.displayDate),callback:function ($$v) {_vm.displayDate=$$v},expression:"displayDate"}})],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"ml-4"},[_vm._v("Zeiterfassung")]):_vm._e(),_c('div',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm.titleTotalDuration))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","dark":"","absolute":"","bottom":"","right":""},on:{"click":_vm.openCreateDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Neue Zeiterfassung")])])],1),_c('v-card-text',[_c('remote-data-table',{ref:"timeEntryDataTable",staticClass:"table",attrs:{"url":_vm.listUrl,"headers":_vm.headers,"sort-by":'start_time'},on:{"dataItems":_vm.updateItems,"dblclick":_vm.dblclick},scopedSlots:_vm._u([{key:"item.budget.project.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.project.name)+" ")])]}},{key:"item.remote_category_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.getCategory(item.remote_category_id))+" ")])]}},{key:"item.billable_rate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.billable_rate)+"%")])]}},{key:"item.start_time",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[(item.remote_category_id === 10 || item.remote_category_id === 11)?_c('span',[_vm._v(" "+_vm._s("00:00")+" ")]):(item.start_time && item.start_time.length > 15)?_c('span',[_vm._v(" "+_vm._s(item.start_time.slice(11, 16))+" ")]):_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("access_time")])],1)],1)]}},{key:"item.end_time",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",staticStyle:{"white-space":"nowrap"}},[(item.remote_category_id === 10 || item.remote_category_id === 11)?_c('span',[_vm._v(" "+_vm._s("00:00")+" ")]):(item.end_time && item.end_time.length > 15)?_c('span',[_vm._v(" "+_vm._s(item.end_time.slice(11, 16))+" ")]):_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("access_time")])],1)],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","text":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":!_vm.isEditable(item)},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_vm._v(" Bearbeiten ")]),_c('v-list-item',{attrs:{"disabled":!_vm.isEditable(item)},on:{"click":function($event){return _vm.deleteEntry(item.id, item.name)}}},[_vm._v(" Löschen ")])],1)],1)]}}],null,true)})],1)],1),_c('time-entry-dialog',{ref:"timeEntryDialog",attrs:{"notConcluded":_vm.notConcluded},on:{"changed":_vm.refresh}}),_c('delete-dialog',{ref:"deleteTimeEntryDialog",on:{"deleted":_vm.refresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }