import { render, staticRenderFns } from "./SupportForm.vue?vue&type=template&id=d99c835e&scoped=true"
import script from "./SupportForm.vue?vue&type=script&lang=js"
export * from "./SupportForm.vue?vue&type=script&lang=js"
import style0 from "./SupportForm.vue?vue&type=style&index=0&id=d99c835e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d99c835e",
  null
  
)

export default component.exports