<template>
  <div>
    <v-card color="default" outlined class="tt-card">
      <v-card-title class="header">
        <div class="title">Offene Aufgaben</div>
      </v-card-title>

      <v-card-text>
        <remote-data-table :url="listUrl" :headers="headers" :sort-by="'created_at'" ref="absenceBackofficeTaskTable" class="table">
          <template v-slot:[`item.task_type`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              <router-link v-if="getType(item.type).link" :to="getType(item.type).link">
                {{ getType(item.type).name }}
              </router-link>
              <div v-else>
                {{ getType(item.type).name }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.employee_ref`]="{ item }">
            <div class="text-caption" style="white-space: nowrap">
              {{ getEmployeeName(item.employee_ref) }}
            </div>
          </template>
          <template v-slot:[`item.instruction`]="{ item }">
            <div class="text-caption">
              {{ getType(item.type).description }}
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn v-if="getType(item.type).has_info" text color="primary" @click="handleInfoAction(item)">
              <v-icon left>info</v-icon>
              Details
            </v-btn>
            <v-btn v-else-if="getType(item.type).action_text !== null" text color="primary" @click="handleRequestAction(item)">
              <v-icon left>task</v-icon>
              {{ getType(item.type).action_text }}
            </v-btn>
            <v-btn v-else text disabled color="primary">
              <v-icon left>autorenew</v-icon>
              Automatisch
            </v-btn>
          </template>
        </remote-data-table>
      </v-card-text>
    </v-card>

    <absence-task-dialog ref="absenceTaskDialog" @done="refresh"> </absence-task-dialog>
    <absence-delete-task-dialog ref="absenceDeleteTaskDialog" @done="refresh"> </absence-delete-task-dialog>
    <admin-task-complete-dialog ref="adminTaskCompleteDialog" @done="refresh"></admin-task-complete-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RemoteDataTable from "@/components/RemoteDataTable";
import AdminTaskCompleteDialog from "@/components/dialogs/AdminTaskCompleteDialog";
import AbsenceTaskDialog from "@/components/dialogs/adminTasks/AbsenceTaskDialog";
import AbsenceDeleteTaskDialog from "@/components/dialogs/adminTasks/AbsenceDeleteTaskDialog";
import { TASKS } from "@/services/AdminTaskService";

export default {
  components: {
    RemoteDataTable,
    AdminTaskCompleteDialog,
    AbsenceTaskDialog,
    AbsenceDeleteTaskDialog,
  },

  data: () => {
    return {
      headers: [
        { text: "Aufgabentyp", align: "left", value: "task_type" },
        { text: "Bezüglich", align: "left", value: "employee_ref" },
        { text: "Instruktion", align: "left", value: "instruction" },
        { text: "Abschliessen", align: "center", value: "action" },
      ],
    };
  },

  computed: {
    ...mapState("user", ["user"]),
    ...mapState("app", ["configs", "overviews"]),

    listUrl() {
      return this.$adminTaskService.getLink();
    },
  },

  methods: {
    getEmployeeName(employeeId) {
      const employee = this.configs.employees.find((e) => e.id === employeeId);
      if (employee) {
        return employee.name;
      }
      return "MA nicht gefunden";
    },

    getType(typeKey) {
      return this.configs.admin_task_type.find((t) => t.key === typeKey);
    },

    handleInfoAction(item) {
      if (item.type === TASKS.ABSENCE_COMATIC) {
        const overview = this.overviews.find((o) => o.employee_id === item.employee_ref);
        const employee = this.configs.employees.find((e) => e.id === item.employee_ref);
        this.$refs.absenceTaskDialog.open(item.task_ref, employee, overview);
      } else if (item.type === TASKS.ABSENCE_COMATIC_DELETE) {
        const employee = this.getEmployeeName(item.employee_ref);
        const overview = this.overviews.find((o) => o.employee_id === item.employee_ref);

        this.$refs.absenceDeleteTaskDialog.open(item.id, item.task_ref, employee, overview);
      }
    },

    handleRequestAction(item) {
      const employee = this.getEmployeeName(item.employee_ref);
      this.$refs.adminTaskCompleteDialog.open(item, employee);
    },

    refresh() {
      this.$refs.absenceBackofficeTaskTable.getDataFromApi();
      this.$emit("refresh");
    },
  },
};
</script>
