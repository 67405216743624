<template>
  <v-container fluid>
    <admin-employee-card @selected="selectEmployee" class="mb-8"></admin-employee-card>
    <!-- <accounting-period-table v-if="selectedEmployee" :employee="selectedEmployee"></accounting-period-table> -->
    <vacation-period-table v-if="selectedEmployee" :employee="selectedEmployee"></vacation-period-table>
  </v-container>
</template>

<script>
// import AccountingPeriodTable from "@/components/tables/admin/AccountingPeriodTable";
import VacationPeriodTable from "@/components/tables/admin/VacationPeriodTable";
import AdminEmployeeCard from "@/components/cards/AdminEmployeeCard";

export default {
  components: {
    // AccountingPeriodTable,
    VacationPeriodTable,
    AdminEmployeeCard,
  },

  data() {
    return {
      selectedEmployee: null,
    };
  },

  methods: {
    selectEmployee(employee) {
      this.selectedEmployee = employee;
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-list {
  max-height: 352px;
}
</style>
