var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"tt-card",attrs:{"color":"default","outlined":""}},[_c('v-card-title',{staticClass:"header",staticStyle:{"position":"relative"}},[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("keyboard_arrow_down")]),_vm._v(" "+_vm._s(_vm.selectedYear)+" ")],1)]}}])},[_c('v-list',_vm._l((_vm.years),function(year){return _c('v-list-item',{key:year,on:{"click":function($event){_vm.selectedYear = year}}},[_vm._v(" "+_vm._s(year)+" ")])}),1)],1),_c('div',{staticClass:"ml-4 grow"},[_vm._v("Abrechnungsperioden")]),_c('v-btn',{on:{"click":function($event){return _vm.$refs.customVacationDialog.open()}}},[_vm._v("Individueller Eintrag")])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filteredPeriods,"loading":_vm.loading,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.month",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMonth(item.month))+" ")]}},{key:"item.changes",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("addPrefix")(item.changes))+" Tag(e)")])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.balance)+" Tag(e)")])]}},{key:"item.comments",fn:function(ref){
var item = ref.item;
return _vm._l((item.comments),function(comment,index){return _c('span',{key:index},[(comment.comment === '' || comment.comment === null)?[_vm._v("-")]:[_vm._v(_vm._s(comment.comment))],_vm._v(" ("+_vm._s(_vm._f("addPrefix")(comment.change))+"; "+_vm._s(_vm.absenceType(comment.type))+") "),(index !== item.comments.length - 1)?[_vm._v(", ")]:_vm._e()],2)})}}],null,true)})],1)],1),_c('custom-vacation-dialog',{ref:"customVacationDialog",attrs:{"employee":this.employee},on:{"done":function($event){return _vm.fetchData()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }