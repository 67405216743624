<template>
  <v-dialog v-model="isOpen" width="500" persistent>
    <v-card class="tt-card d-flex flex-column justify-space-between">
      <v-card-title class="header">
        <div class="title">Belege <span v-if="isMobile">(Mobile)</span></div>
      </v-card-title>

      <v-tabs v-model="tab">
        <v-tab v-for="tab in tabs" :key="tab">
          {{ tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="'Neuer Beleg'">
          <v-card-text>
            <v-row>
              <v-col>
                <input style="display: none" type="file" v-on:change="onChangeFiles" ref="fileSelection" accept="image/*" />
                <template v-if="isMobile">
                  <input style="display: none" type="file" v-on:change="onChangeFiles" ref="fileSelectionCamera" accept="image/*" capture="environment" />

                  <div class="d-flex justify-center">
                    <v-sheet height="70" width="70" class="zone mr-4 " :class="{ 'zone-disabled': thumbnails.length > 0 }" @click="$refs.fileSelectionCamera.click()">
                      <v-icon x-large :color="thumbnails.length > 0 ? 'gray' : 'accent'">camera</v-icon>
                    </v-sheet>
                    <v-sheet height="70" width="70" class="zone ml-4" :class="{ 'zone-disabled': thumbnails.length > 0 }" @click="$refs.fileSelection.click()">
                      <v-icon x-large :color="thumbnails.length > 0 ? 'gray' : 'accent'">upload</v-icon>
                    </v-sheet>
                  </div>
                </template>
                <template v-else>
                  <v-sheet height="150" class="zone" :class="{ 'zone-disabled': thumbnails.length > 0 }" @click="$refs.fileSelection.click()" @dragover="onDragOver" @drop="onDropFiles">
                    <v-icon x-large :color="thumbnails.length > 0 ? 'gray' : 'accent'">upload</v-icon>
                    <div>(Klicke oder ziehe Bilder rein)</div>
                  </v-sheet>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="d-flex py-4 justify-space-between" v-if="file !== null" :key="file.id">
                  <div class="d-flex mr-4">
                    <div class="mr-6">
                      <v-img :src="file.base64" max-height="45" max-width="45" class="preview-image rounded"></v-img>
                    </div>
                    <div class="text-elipsis text-body">{{ file.raw.name }}</div>
                  </div>
                  <div class="d-flex">
                    <div class="mr-4">
                      <v-btn icon large>
                        <v-icon color="accent" @click="openCropDialog()">edit</v-icon>
                      </v-btn>
                    </div>
                    <div>
                      <v-btn icon large>
                        <v-icon color="gray" @click="removeFile()">delete</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-tab-item>
        <v-tab-item :key="'Übersicht'">
          <v-card flat>
            <v-card-text>
              <div class="d-flex flex-wrap justify-space-between">
                <div v-for="thumbnail in thumbnails" v-bind:key="thumbnail.Filename" class="mt-4">
                  <v-img :src="thumbnail.Thumbnail" height="200" width="200" class="preview-image rounded"></v-img>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="close()">Fertig</v-btn>
      </v-card-actions>
    </v-card>
    <image-crop-dialog ref="imageCropDialog" @cropped="cropped"></image-crop-dialog>
  </v-dialog>
</template>

<script>
import ImageCropDialog from "./ImageCropDialog.vue";
export default {
  components: {
    ImageCropDialog,
  },
  props: {
    value: {
      required: true,
      default: null,
    },
    thumbnails: {
      required: false,
      default: [],
    },
  },
  data() {
    return {
      isOpen: false,
      tabs: ["Neuer Beleg", "Übersicht"],
      tab: null,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    file: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {},
  watch: {},
  methods: {
    open() {
      this.isOpen = true;
    },

    close() {
      this.isOpen = false;
    },

    reset() {
      this.file = null;
      this.$refs.fileSelection.value = "";
    },

    onChangeFiles(event) {
      this.addRawFile(event.srcElement.files[0]);
    },

    onDropFiles(event) {
      event.preventDefault();

      if (event.dataTransfer.items) {
        const item = [...event.dataTransfer.items][0];
        if (item.kind === "file") {
          const file = item.getAsFile();
          if (file.name.endsWith(".jpg") || file.name.endsWith(".jpeg") || file.name.endsWith(".png")) {
            this.addRawFile(file);
          }
        }
      } else {
        this.addRawFile(event.dataTransfer.files[0]);
      }
    },

    onDragOver(event) {
      event.preventDefault();
    },

    removeFile() {
      this.file = null;
    },

    async addRawFile(rawFile) {
      const customFile = await this.wrappToCustomFile(rawFile);
      this.file = customFile;
    },

    async wrappToCustomFile(rawFile) {
      const base64 = await this.readFileAsBase64(rawFile);
      return { raw: rawFile, base64: base64, id: Math.round(Math.random() * 10000000), name: rawFile.name };
    },

    async readFileAsBase64(rawFile) {
      var reader = new FileReader();
      reader.readAsDataURL(rawFile);

      return new Promise((resolve, reject) => {
        reader.onload = function () {
          // console.log(reader.result);
          resolve(reader.result);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
          reject(error);
        };
        reader.abort = function (error) {
          console.log("Error: ", error);
          reject(error);
        };
      });
    },

    openCropDialog() {
      this.$refs.imageCropDialog.openWithFile(this.file);
    },

    cropped(file1) {
      console.log(file1);
    },

    getFiles() {
      this.$documentService.index();
    },
  },
};
</script>

<style lang="scss" scoped>
.zone {
  border: dashed 3px #34c19a !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  cursor: pointer;

  &-disabled {
    opacity: 0.6;
    pointer-events: none;
    border: dashed 3px gray !important;
  }
}

.preview-image {
  border: solid 2px #34c19a;
}

.text-elipsis {
  max-height: 45px;
  // max-width: 70%;
  overflow: hidden;
  overflow-wrap: anywhere;
  // text-overflow: ellipsis;
  // white-space: nowrap !important;
}
</style>
