export default class EmployeeVacationService {
  constructor(api) {
    this.api = api;
  }

  index(employeeId) {
    return this.api.http.get(`api/employees/${employeeId}/vacations`);
  }

  store(employeeId, data) {
    return this.api.http.post(`api/employees/${employeeId}/vacations`, data);
  }

  accountingPeriods(employeeId) {
    return this.api.http.get(`api/employees/${employeeId}/vacations/accountingPeriods`);
  }

  stats(employeeId) {
    return this.api.http.get(`api/employees/${employeeId}/vacations/stats`);
  }
}
