<template>
  <v-dialog v-model="isOpen" scrollable width="500">
    <v-card>
      <v-card-title :class="['primary', 'white--text']">
        <span class="subtitle-1">Anfrage beantworten</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <p>
          Absenz vom <strong>{{ startDate }}</strong> bis am
          <strong>{{ endDate }}</strong>
        </p>
        <p>
          Guthaben alt: <strong>{{ daysLeft || 0 }}</strong
          >, Guthaben neu: <strong>{{ daysLeft + diffInDays || 0}}</strong
          >, Differenz: <strong>{{ diffInDays || 0 }}</strong>
        </p>
        <p>
          Soll der Absenzeintrag von
          <strong>{{ employeeName }}</strong> angenommen werden?
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="close()"> Abbrechen </v-btn>
        <v-btn color="error" @click="completeRequest('denied')" class="ml-6 px-4"> Ablehnen </v-btn>
        <v-btn color="accent" @click="completeRequest('approved')" class="ml-6 px-4"> Annehmen </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    isOpen: false,
    employee: null,
    request: null,
    overview: null,
    daysLeft: null,
    diffInDays: 0,
  }),

  methods: {
    open(request, employee, overview) {
      this.isOpen = true;
      this.request = request;
      this.employee = employee;
      this.overview = overview;

      this.fetchDaysLeft();
    },

    close() {
      this.isOpen = false;
      this.reset();
    },

    reset() {
      this.request = null;
      this.employee = "";
      this.overview = null;
    },

    completeRequest(state) {
      const text = state === "denied" ? "abgelehnt" : "angenommen";
      this.$absenceRequestService
        .changeManagerRequestState(this.request.id, state)
        .then(() => {
          this.$toast.open(`Eintrag wurde erfolgreich ${text}.`);
          this.$emit("done");
        })
        .catch(() => {
          this.$toast.open({
            message: `Eintrag konnte nicht ${text} werden.`,
            type: "error",
          });
        })
        .finally(() => {
          this.close();
        });
    },

    fetchDaysLeft() {
      this.$employeeVacationService.stats(this.employee.id).then(({ data }) => {
        this.daysLeft = data.netto;
      });
    },
  },

  computed: {
    startDate() {
      if (this.request) {
        return moment(this.request.start).format("DD.MM.YYYY");
      } else {
        return "";
      }
    },
    endDate() {
      if (this.request) {
        return moment(this.request.end).format("DD.MM.YYYY");
      } else {
        return "";
      }
    },
    employeeName() {
      if (this.employee) {
        return this.employee.name;
      } else {
        return "";
      }
    },
  },

  watch: {
    async request(request) {
      if (request !== null) {
        this.diffInDays = await this.$holidayService.calculateChange(moment(this.request.start).format("YYYY-MM-DD"), moment(this.request.end).format("YYYY-MM-DD")).then(({ data }) => data);
      }
    },
  },
};
</script>
