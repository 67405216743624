<template>
  <v-dialog v-model="isOpen" width="500">
    <v-card class="tt-card">
      <v-card-title class="header">
        <div class="title">Beleg Raufladen</div>
      </v-card-title>
      <v-card-text>
        <cropper
          class="cropper"
          :src="base64"
          @change="onCropperChange"
        >
        </cropper>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="close()">Abbrechen</v-btn>
        <v-btn color="primary" @click="crop()">Zuschneiden</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  components: {
    Cropper,
  },
  props: {},
  data() {
    return {
      isOpen: false,
      customFile: null,
      base64: "",
      canvas: null,
    };
  },
  computed: {},
  mounted() {},
  watch: {
    isOpen(isOpen) {
      // if modal closes, reset modal
      if (!isOpen) {
        this.reset();
      }
    },
  },
  methods: {
    openWithFile(customFile) {
      this.customFile = customFile;
      this.base64 = customFile.base64;
      this.isOpen = true;
    },

    close() {
      this.isOpen = false;
    },

    reset() {
      this.customFile = null;
      this.img = null;
      this.canvas = null;
    },

    onCropperChange({ coordinates, canvas }) {
      this.canvas = canvas;
      console.log(coordinates, canvas);
    },

    crop() {
      this.close();

      this.customFile.base64 = this.canvas.toDataURL("image/jpeg");
      this.$emit("cropped", this.customFile);
    },

    upload() {},
  },
};
</script>

<style lang="scss" scoped></style>
